// Generated by Framer (10753fe)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {Go3yiZNcU: {hover: true}};

const cycleOrder = ["Go3yiZNcU"];

const variantClassNames = {Go3yiZNcU: "framer-v-tmzhs5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string}; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Go3yiZNcU", image: WUIidsuV5, tap: g0xSKlbfE, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Go3yiZNcU", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapa0d0va = activeVariantCallback(async (...args) => {
if (g0xSKlbfE) {
const res = await g0xSKlbfE(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-XpAQd", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} background={{alt: "", fit: "fill"}} className={cx("framer-tmzhs5", className)} data-framer-name={"Image - Fill"} data-highlight layoutDependency={layoutDependency} layoutId={"Go3yiZNcU"} onTap={onTapa0d0va} ref={ref} style={{boxShadow: "none", ...style}} transition={transition} variants={{"Go3yiZNcU-hover": {boxShadow: "0.18065619053231785px 0.6021873017743928px 0.8801828008817865px -0.9166666666666666px rgba(0, 0, 0, 0.19039), 0.6865599909730371px 2.288533303243457px 3.3450184798395717px -1.8333333333333333px rgba(0, 0, 0, 0.18449), 3px 10px 14.61642911247477px -2.75px rgba(0, 0, 0, 0.1575)"}}} {...addPropertyOverrides({"Go3yiZNcU-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: "min(372px, 100vw)", ...toResponsiveImage(WUIidsuV5)}} className={"framer-177rv4v"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"ggrR4hKYQ"} transition={transition} {...addPropertyOverrides({"Go3yiZNcU-hover": {background: {alt: "", fit: "fill", sizes: "calc(min(372px, 100vw) * 1.0968)", ...toResponsiveImage(WUIidsuV5)}}}, baseVariant, gestureVariant)}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-XpAQd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XpAQd .framer-1mrvr8f { display: block; }", ".framer-XpAQd .framer-tmzhs5 { cursor: pointer; height: 316px; overflow: hidden; position: relative; width: 372px; }", ".framer-XpAQd .framer-177rv4v { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 100%; }", ".framer-XpAQd .framer-v-tmzhs5 .framer-tmzhs5 { cursor: pointer; }", ".framer-XpAQd.framer-v-tmzhs5.hover .framer-177rv4v { height: 110%; left: calc(50.00000000000002% - 109.6774193548387% / 2); top: calc(50.00000000000002% - 109.81012658227849% / 2); width: 110%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 316
 * @framerIntrinsicWidth 372
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"v1hiZav1P":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WUIidsuV5":"image","g0xSKlbfE":"tap"}
 */
const FrameryRDNaTWCj: React.ComponentType<Props> = withCSS(Component, css, "framer-XpAQd") as typeof Component;
export default FrameryRDNaTWCj;

FrameryRDNaTWCj.displayName = "Elemental/ Featured Image";

FrameryRDNaTWCj.defaultProps = {height: 316, width: 372};

addPropertyControls(FrameryRDNaTWCj, {WUIidsuV5: {title: "Image", type: ControlType.ResponsiveImage}, g0xSKlbfE: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FrameryRDNaTWCj, [])